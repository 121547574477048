import type { 
  Intervention,
  InterventionItem,
  InterventionPlannedService,
  InterventionPlannedServiceSparePart,
} from "@laast-io/types";
import { hydrateDate, hydrateNumber } from "./common";
import { hydrateCustomerDeviceRelation, hydrateCustomerRelation } from "./customer";
import { hydrateDeviceModelRelation } from "./device-model";
import { hydrateInvoiceRelation } from "./invoice";
import { hydrateItemRelation } from "./item";
import { hydrateServiceRelation } from "./service";
import { hydrateSparePartRelation } from "./spare-part";
import { hydrateTransactionRelation } from "./transaction";

export function hydrateInterventionRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as Intervention['id'] | null | undefined
  }

  return hydrateIntervention(input)
}
export function hydrateIntervention(
  input: any
) {
  return {
    ...(input as Intervention),

    // hydrate relations
    device_model: hydrateDeviceModelRelation(input.device_model),
    customer: hydrateCustomerRelation(input.customer),
    customer_device: hydrateCustomerDeviceRelation(input.customer_device),
    items: input.items?.map(hydrateInterventionItemRelation),
    planned_services: input.planned_services?.map(hydrateInterventionPlannedServiceRelation),
    invoices: input.invoices?.map(hydrateInvoiceRelation),
    transactions: input.transactions?.map(hydrateTransactionRelation),

    // hydrate numbers
    estimated_time: hydrateNumber(input.estimated_time),

    // hydrate dates
    date_created: hydrateDate(input.date_created),
    date_updated: hydrateDate(input.date_updated),
    date_scheduled: hydrateDate(input.date_scheduled),
    date_estimated: hydrateDate(input.date_estimated),
    date_started: hydrateDate(input.date_started),
    date_payed: hydrateDate(input.date_payed),
    date_completed: hydrateDate(input.date_completed),
    date_status_updated: hydrateDate(input.date_status_updated),
  }
}

export function hydrateInterventionItemRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as InterventionItem['id'] | null | undefined
  }

  return hydrateInterventionItem(input)
}
export function hydrateInterventionItem(
  input: any
) {
  return {
    ...input as InterventionItem,

    // hydrate relations
    item: hydrateItemRelation(input.item),
    
    // hydrate numbers
    id: hydrateNumber(input.id),
    discount: hydrateNumber(input.discount),
    quantity: hydrateNumber(input.quantity),
    stock_deducted: hydrateNumber(input.stock_deducted),

  }
}

export function hydrateInterventionPlannedServiceRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as InterventionPlannedService['id'] | null | undefined
  }

  return hydrateInterventionPlannedService(input)
}
export function hydrateInterventionPlannedService(
  input: any
) {
  return {
    ...input as InterventionPlannedService,

    // hydrate relations
    service: hydrateServiceRelation(input.service),
    require_spare_part: input.require_spare_part?.map(hydrateInterventionPlannedServiceSparePartRelation),
    
    // hydrate numbers
    id: hydrateNumber(input.id),
    discount: hydrateNumber(input.discount),
  }
}


export function hydrateInterventionPlannedServiceSparePartRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as InterventionPlannedServiceSparePart['id'] | null | undefined
  }

  return hydrateInterventionPlannedServiceSparePart(input)
}
export function hydrateInterventionPlannedServiceSparePart(
  input: any
) {
  return {
    ...input as InterventionPlannedServiceSparePart,

    // hydrate relations
    intervention_planned_service: hydrateInterventionPlannedServiceRelation(input.intervention_planned_service),
    spare_part: hydrateSparePartRelation(input.spare_part),
    
    // hydrate numbers
    id: hydrateNumber(input.id),
    price_modifier: hydrateNumber(input.price_modifier),
    quantity: hydrateNumber(input.quantity),
    stock_deducted: hydrateNumber(input.stock_deducted),
  }
}