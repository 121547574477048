import type { 
  DeviceModel,
  Service,
  ServiceRequireSparePart,
} from "@laast-io/types";
import { hydrateNumber } from "./common";
import { hydrateDeviceModelRelation } from "./device-model";
import { hydrateSparePart, hydrateSparePartRelation } from "./spare-part";

export function hydrateServiceRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as Service['id'] | null | undefined
  }

  return hydrateService(input)
}
export function hydrateService(
  input: any
) {
  return {
    ...(input as Service),

    // hydrate relations
    device_model: hydrateDeviceModelRelation(input.device_model),
    require_spare_parts: input.require_spare_parts?.map(hydrateServiceRequireSparePartRelation),

    // hydrate numbers
    tax_ratio: hydrateNumber(input.tax_ratio),
    unitary_price: hydrateNumber(input.unitary_price),
    estimated_time: hydrateNumber(input.estimated_time),
  }
}


export function hydrateServiceRequireSparePartRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as ServiceRequireSparePart['id'] | null | undefined
  }

  return hydrateServiceRequireSparePart(input)
}

export function hydrateServiceRequireSparePart(
  input: any
) {
  return {
    ...input as ServiceRequireSparePart,

    // hydrate relations
    spare_part: hydrateSparePartRelation(input.spare_part),
    service: hydrateServiceRelation(input.service),

    // hydrate numbers
    id: hydrateNumber(input.id),
    price_modifier: hydrateNumber(input.price_modifier),
    quantity: hydrateNumber(input.quantity),
  }
}