import type { SparePart } from "@laast-io/types";
import { hydrateNumber } from "./common";
import { hydrateInterventionPlannedServiceSparePartRelation } from "./intervention";
import { hydrateServiceRequireSparePartRelation } from "./service";


export function hydrateSparePartRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as SparePart['id'] | null | undefined
  }

  return hydrateSparePart(input)
}

export function hydrateSparePart(
  input: any
) {
  return {
    ...input as SparePart,

    required_in_planned_services: input.required_in_planned_services
      ?.map(hydrateInterventionPlannedServiceSparePartRelation),
    required_in_services: input.required_in_services
      ?.map(hydrateServiceRequireSparePartRelation),

    // hydrate numbers
    in_stock: hydrateNumber(input.in_stock),
    stock_threshold_optimal: hydrateNumber(input.stock_threshold_optimal),
    stock_threshold_warning: hydrateNumber(input.stock_threshold_warning),
    unitary_cost: hydrateNumber(input.unitary_cost),

    /** @deprecated */
    stock_threshold_critical: hydrateNumber(input.stock_threshold_critical),
  }
}
