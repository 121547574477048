import type { Transaction } from "@laast-io/types";
import { hydrateDate, hydrateNumber } from "./common";
import { hydrateCustomerRelation } from "./customer";
import { hydrateInterventionRelation } from "./intervention";
import { hydrateInvoiceRelation } from "./invoice";

export function hydrateTransactionRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as Transaction['id'] | null | undefined
  }

  return hydrateTransaction(input)
}

export function hydrateTransaction(
  input: any
) {
  return {
    ...input as Transaction,

    // hydrate relations
    intervention: hydrateInterventionRelation(input.intervention),
    customer: hydrateCustomerRelation(input.customer),
    invoice: hydrateInvoiceRelation(input.invoice),

    // hydrate numbers
    amount: hydrateNumber(input.amount),

    // hydrate dates
    date_created: hydrateDate(input.date_created),
    date_updated: hydrateDate(input.date_updated),
  }
}