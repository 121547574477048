import type { 
  Customer,
  CustomerAddress,
  CustomerDevice,
  CustomerMessage,
} from "@laast-io/types";
import { hydrateDate, hydrateNumber } from "./common";
import { hydrateInterventionRelation } from "./intervention";
import { hydrateInvoice, hydrateInvoiceRelation } from "./invoice";
import { hydrateTransaction, hydrateTransactionRelation } from "./transaction";

export function hydrateCustomerRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as Customer['id'] | null | undefined
  }
  return hydrateCustomer(input)
}
export function hydrateCustomer(
  input: any
) {
  return {
    ...(input as Customer),

    // hydrate relations
    messages: input.messages
      ?.map(hydrateCustomerMessageRelation)
      ?.filter(Boolean),
    addresses: input.addresses
      ?.map(hydrateCustomerAddressRelation)
      ?.filter(Boolean),
    devices: input.devices
      ?.map(hydrateCustomerDeviceRelation)
      ?.filter(Boolean),
    invoices: input.invoices
      ?.map(hydrateInvoiceRelation)
      ?.filter(Boolean),
    transactions: input.transactions
      ?.map(hydrateTransactionRelation)
      ?.filter(Boolean),

    // hydrate numbers
    credit: hydrateNumber(input.credit),
    messages_agent_unread: hydrateNumber(input.messages_agent_unread),
  }
}

export function hydrateCustomerDeviceRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as CustomerDevice['id'] | null | undefined
  }

  return hydrateCustomerDevice(input)
}
export function hydrateCustomerDevice(
  input: any
) {
  return {
    ...(input as CustomerDevice),
  }
}

export function hydrateCustomerAddressRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as CustomerAddress['id'] | null | undefined
  }

  return hydrateCustomerAddress(input)
}

export function hydrateCustomerAddress(
  input: any
) {
  return {
    ...(input as CustomerAddress),
  }
}


export function hydrateCustomerMessageRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as CustomerMessage['id'] | null | undefined
  }

  return hydrateCustomerMessage(input)
}

export function hydrateCustomerMessage(
  input: any
) {
  return {
    ...(input as CustomerMessage),

    // hydrate relations
    intervention: hydrateInterventionRelation(input.intervention),

    // hydrate dates
    date_created: hydrateDate(input.date_created),
    date_updated: hydrateDate(input.date_updated),
  }
}