import type { Invoice } from "@laast-io/types";
import { hydrateDate, hydrateNumber } from "./common";
import { hydrateCustomerRelation } from "./customer";
import { hydrateInterventionRelation } from "./intervention";
import { hydrateTransactionRelation } from "./transaction";


export function hydrateInvoiceRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as Invoice['id'] | null | undefined
  }

  return hydrateInvoice(input)
}
export function hydrateInvoice(
  input: any
) {
  return {
    ...input as Invoice,

    // hydrate json
    details: input.details as Record<string, any>,

    // hydrate relations
    customer: hydrateCustomerRelation(input.customer),
    intervention: hydrateInterventionRelation(input.intervention),
    transactions: input.transactions?.map(hydrateTransactionRelation),

    // hydrate numbers
    id: hydrateNumber(input.id),
    cost_total: hydrateNumber(input.cost_total),
    discount_total: hydrateNumber(input.discount_total),
    duration: hydrateNumber(input.duration),
    gain_total: hydrateNumber(input.gain_total),
    item_cost_total: hydrateNumber(input.item_cost_total),
    item_discount_total: hydrateNumber(input.item_discount_total),
    item_gain_total: hydrateNumber(input.item_gain_total),
    item_quantity: hydrateNumber(input.item_quantity),
    item_subtotal: hydrateNumber(input.item_subtotal),
    item_tax_margin_total: hydrateNumber(input.item_tax_margin_total),
    item_tax_total: hydrateNumber(input.item_tax_total),
    item_total: hydrateNumber(input.item_total),
    service_cost_total: hydrateNumber(input.service_cost_total),
    service_discount_total: hydrateNumber(input.service_discount_total),
    service_gain_total: hydrateNumber(input.service_gain_total),
    service_subtotal: hydrateNumber(input.service_subtotal),
    service_tax_total: hydrateNumber(input.service_tax_total),
    service_total: hydrateNumber(input.service_total),
    subtotal: hydrateNumber(input.subtotal),
    tax_total: hydrateNumber(input.tax_total),
    total: hydrateNumber(input.total),

    // hydrate dates
    date_payed: hydrateDate(input.date_payed),
  }
}